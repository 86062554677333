
import { Vue, Component } from 'vue-property-decorator'
import DeployPage from '@/components/deploy-page.vue'

@Component({
  name: 'AppBatchDeployPage',
  components: { DeployPage }
})
export default class AppBatchDeployPage extends Vue {
  applicationId = 0

  created () {
    // 获取跳转传递过来的实例id
    const id = this.$route.params.id
    if (!id) {
      this.$Message.error('缺少必要参数id')
      return
    }
    this.applicationId = parseInt(id)
  }
}
